
import { defineComponent, ref, onMounted, onUpdated, reactive } from "vue";

//import { translate } from "element-plus";
import ApiService from "@/core/services/ApiService";
import axios from "axios";
import { useField, useForm } from "vee-validate";
import * as Yup from "yup";
import store from "@/store";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "schedulePage",
  data() {
    return {
      tourContent: "",
      uid: localStorage.getItem("uid"),
      data: "",
      maxPage: 1,
      page: Number(this.$route.params.page),
      pagination: {
        rowsPerPage: 20, // current rows per page being displayed
      },
    };
  },
  mounted() {
    if (!store.state.uid) {
      ElMessage.error(this.$t("pleaseLogin"));
      this.$router.push({ name: "login" });
    }
    if (!this.$route.params.page) {
      this.$router.push({ name: "schedule", params: { page: 1 } });
      this.page = 1;
    }
    this.getTourData();
  },
  methods: {
    imageLoadError(e) {
      e.target.src =
        "https://php.rankopen.com/uc_server/data/avatar/image/default.png?version=1";
      //e.target.classList.toggle('d-none')
    },
    getTourData() {
      this.data = "";
      if (store.state.uid) {
        axios
          .post(
            process.env.VUE_APP_API_URL +
              "&mode=jwt&tab=schedule&page=" +
              this.page,
            JSON.stringify({ lang: this.$i18n.locale, uid: store.state.uid })
          )
          .then((response) => {
            console.log(response.data.data);
            this.data = response.data.data;
            this.maxPage = response.data.maxPage;
          });
      }
    },
    tourCol(format) {
      if (["minislam", "minislamWS", "minislamAD"].includes(format)) {
        return this.minislam_col;
      } else if (format === "semislam") {
        return this.semislam_col;
      } else if (format === "4" || format === "6") {
        return this.buddies_col;
      } else if (format === "doublecup") {
        return this.double_col;
      }
    },
    roundText(round) {
      if (round === "f") {
        return this.$t("roundF");
      } else if (round === "sf") {
        return this.$t("roundSF");
      } else if (round === "qf") {
        return this.$t("roundQF");
      } else {
        return this.$t("round" + round);
      }
    },
    translatePlace(place) {
      if (place === "中西區") return this.$t("cwd");
      else if (place === "灣仔") return this.$t("wanChai");
      else if (place === "東區") return this.$t("eastD");
      else if (place === "南區") return this.$t("southD");
      else if (place === "油尖旺") return this.$t("ytm");
      else if (place === "深水&#22487;") return this.$t("ssp");
      else if (place === "九龍城") return this.$t("klnCity");
      else if (place === "黃大仙") return this.$t("wts");
      else if (place === "觀塘") return this.$t("kwunTong");
      else if (place === "葵青") return this.$t("kwaiTsing");
      else if (place === "荃灣") return this.$t("tsuenWan");
      else if (place === "屯門") return this.$t("tuenMun");
      else if (place === "元朗") return this.$t("yuenLong");
      else if (place === "北區") return this.$t("northD");
      else if (place === "大埔") return this.$t("taiPo");
      else if (place === "沙田") return this.$t("shaTin");
      else if (place === "西貢") return this.$t("saiKung");
      else if (place === "離島") return this.$t("island");
      else if (place === "其他") return this.$t("other");

      return place;
    },
  },
  watch: {
    page() {
      // When change of single and double
      this.$router.push({ name: "schedule", params: { page: this.page } });
      this.getTourData();
    },
  },

  updated() {
    if (!this.$route.params.page) {
      this.$router.push({ name: "schedule", params: { page: 1 } });
    }
  },
  computed: {
    minislam_col() {
      return [
        {
          name: "repoint",
          align: "center",
          label: this.$t("repoint"),
          field: "repoint",
        },
        {
          name: "round",
          align: "left",
          label: this.$t("round"),
          field: "round",
        },
        {
          name: "oppo",
          align: "left",
          label: this.$t("opponent"),
          field: "oppo",
        },
        {
          name: "confirmed",
          align: "left",
          label: this.$t("sStatus"),
          field: "confirmed",
        },
        {
          name: "time",
          align: "center",
          label: this.$t("time"),
          field: "time",
        },
        {
          name: "place",
          align: "center",
          label: this.$t("place"),
          field: "place",
        },
        {
          name: "contact",
          align: "center",
          label: this.$t("contact2"),
          field: "contact",
        },
        {
          name: "oppoLocation",
          align: "center",
          label: this.$t("oppoLocation"),
          field: "oppoLocation",
        },
        {
          name: "deadline",
          align: "center",
          label: this.$t("deadline2"),
          field: "deadline",
        },
        {
          name: "practice",
          align: "center",
          label: this.$t("practiceAfter"),
          field: "practice",
        },
      ];
    },
    semislam_col() {
      return [
        {
          name: "repoint",
          align: "center",
          label: this.$t("repoint"),
          field: "repoint",
        },
        {
          name: "round",
          align: "left",
          label: this.$t("round"),
          field: "round",
        },
        {
          name: "confirmed",
          align: "left",
          label: this.$t("sStatus"),
          field: "confirmed",
        },
        {
          name: "oppo",
          align: "left",
          label: this.$t("opponent"),
          field: "oppo",
        },
        {
          name: "time",
          align: "center",
          label: this.$t("time"),
          field: "time",
        },
        {
          name: "place",
          align: "center",
          label: this.$t("place"),
          field: "place",
        },
        {
          name: "contact",
          align: "center",
          label: this.$t("contact2"),
          field: "contact",
        },
        {
          name: "oppoLocation",
          align: "center",
          label: this.$t("oppoLocation"),
          field: "oppoLocation",
        },
        {
          name: "deadline",
          align: "center",
          label: this.$t("deadline2"),
          field: "deadline",
        },
        {
          name: "practice",
          align: "center",
          label: this.$t("practiceAfter"),
          field: "practice",
        },
      ];
    },
    buddies_col() {
      return [
        {
          name: "repoint",
          align: "center",
          label: this.$t("repoint"),
          field: "repoint",
        },
        {
          name: "oppo",
          align: "left",
          label: this.$t("opponent"),
          field: "oppo",
        },
        {
          name: "confirmed",
          align: "left",
          label: this.$t("sStatus"),
          field: "confirmed",
        },
        {
          name: "time",
          align: "center",
          label: this.$t("time"),
          field: "time",
        },
        {
          name: "place",
          align: "center",
          label: this.$t("place"),
          field: "place",
        },
        {
          name: "contact",
          align: "center",
          label: this.$t("contact2"),
          field: "contact",
        },
        {
          name: "oppoLocation",
          align: "center",
          label: this.$t("oppoLocation"),
          field: "oppoLocation",
        },
        {
          name: "practice",
          align: "center",
          label: this.$t("practiceAfter"),
          field: "practice",
        },
      ];
    },
    double_col() {
      return [
        {
          name: "repoint",
          align: "center",
          label: this.$t("repoint"),
          field: "repoint",
        },
        {
          name: "oppoDouble",
          align: "left",
          label: this.$t("opponent"),
          field: "oppoDouble",
        },
        {
          name: "confirmed",
          align: "left",
          label: this.$t("sStatus"),
          field: "confirmed",
        },
        {
          name: "time",
          align: "center",
          label: this.$t("time"),
          field: "time",
        },
        {
          name: "place",
          align: "center",
          label: this.$t("place"),
          field: "place",
        },
        {
          name: "contact",
          align: "center",
          label: this.$t("contact2"),
          field: "contact",
        },
      ];
    },
  },
});
